import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";

import logo2 from "../../../ressources/images/logo-cts.png";
import logo3 from "../../../ressources/images/logo-electricite-strasbourg.png";
import logo4 from "../../../ressources/images/logo-omnicell.png";
import logo5 from "../../../ressources/images/logo-socomec.jpg";
import logo6 from "../../../ressources/images/logo-vetoquinol.png";
import logo7 from "../../../ressources/images/Logo_weewio.png";
import logo8 from "../../../ressources/images/logo_roiret_transport.png";

export default function Temoignages() {
  const [scrollSpeed, setScrollSpeed] = useState(0.5); // vitesse de défilement par défaut
  const options = {
    type: "loop",
    gap: "10vw",
    arrows: false,
    pagination: false,
    perPage: 3,
    autoScroll: {
      pauseOnHover: false,
      pauseOnFocus: false,
      rewind: true,
      speed: scrollSpeed
    }
  }

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) { // écran de téléphone
        setScrollSpeed(0.2);
      } else if (screenWidth < 1024) { // écran de tablette
        setScrollSpeed(0.4);
      } else { // écran d'ordinateur
        setScrollSpeed(0.5);
      }
    }

    handleResize(); // appel initial
    window.addEventListener('resize', handleResize); // abonnement au redimensionnement de la fenêtre
    return () => {
      window.removeEventListener('resize', handleResize); // désabonnement du redimensionnement de la fenêtre
    }
  }, []);

  return (
    <div className=' my-14'>
      <div className='border-y-2 py-2'>
        <Splide options={ options } extensions={{ AutoScroll }} >
          {/* Changer la width des logos pour les agrandir ou les rétrécir (le responsive est automatique) */}
          <SplideSlide> 
            <a href='https://www.cts-strasbourg.eu' target='_blank' rel="noreferrer">
              <img src={logo2} className='w-[200px] h-auto' alt=''/>
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href='https://www.es.fr' target='_blank' rel="noreferrer">
                <img src={logo3} className='w-[170px] h-auto' alt=''/>
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href='https://www.omnicell.com' target='_blank' rel="noreferrer"> 
              <img src={logo4} className='w-[250px] h-auto' alt=''/>
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href='https://www.socomec.fr' target='_blank' rel="noreferrer"> 
              <img src={logo5} className='w-[280px] h-auto' alt=''/>
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href='https://www.vetoquinol.com' target='_blank' rel="noreferrer">
              <img src={logo6} className='w-[200px] h-auto' alt='' />
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href='https://weewio.com/' target='_blank' rel="noreferrer">
              <img src={logo7} className='w-[200px] h-auto' alt='' />
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href='https://www.roiret-transport.fr/' target='_blank' rel="noreferrer">
              <img src={logo8} className='w-[200px] h-auto' alt='' />
            </a>
          </SplideSlide>
        </Splide>
      </div>

    </div>
  );
}
