import React from 'react';
import Button from "../../../components/Button";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Locaux2 from "../../../ressources/images/locaux2.jpg";
import Locaux3 from "../../../ressources/images/locaux3.jpg";
import i18n from "../../../I18next/i18n";
import { useTranslation } from 'react-i18next';

export default function NousRejoindre() {
  var Carousel = require('react-responsive-carousel').Carousel;
  const { t } = useTranslation();
  return (
    <div className='mt-32 mx-auto wrapper xl:mt-[230px] mb-36 xl:mb-[230px]'>
        <h1 className="text-4xl font-bold text-center mb-2 md:text-5xl md:mb-20" data-aos="zoom-in">
            {t("rejoindre_rejoindre1")}
            <span className="text-primOrange"> {t("rejoindre_rejoindre2")}</span> 
        </h1>
        <div className="w-full xl:flex xl:gap-16 xl:items-center" data-aos="zoom-in">
          <div className='xl:w-1/2'>
            <p className='text-left font-roboto font-light leading-7 mt-9 md:text-lg md:mt-12 xl:text-lg xl:leading-8' data-aos="fade-right">
            {t("rejoindre_text1")}
              <br/> <br/> {t("rejoindre_text2")} 
              <li className='mt-4'>{t("rejoindre_li1")}</li>
              <li>{t("rejoindre_li2")}</li>
              <li>{t("rejoindre_li3")}</li>
              <li>{t("rejoindre_li4")}</li>
            </p>
            <div className="flex justify-center mt-7">
              <Button to="carrieres" texte={t("rejoindre_plus")}/>
            </div>
          </div>
          <Carousel className='mx-auto relative mt-9 md:w-[570px] xl:w-1/2 ' showThumbs={false} showStatus={false}>
            <div className='mt-5'>
              <img src={Locaux2} className='rounded-[26px] h-[250px] md:h-[330px] xl:h-[500px] xl:max-h-3xl'alt='Locaux'/>
            </div>
            <div className='mt-5'>
              <img src={Locaux3} className='rounded-[26px] h-[250px] md:h-[330px] xl:h-[500px] xl:max-[h-3xl]' alt='Locaux2'/>
            </div>
          </Carousel>
        </div>
    </div>
  )
}
