import React, { useEffect } from 'react';
import '../../ressources/styles/contact.css';
import Mallette from '../../ressources/images/mallette.svg';
import Devanture from '../../ressources/images/facade immeuble 2.jpg';
import Typewriter from 'typewriter-effect/dist/core';
import Form from './Content/Form';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function Contact() {
  const { t } = useTranslation();
  
  useEffect(() => {
    new Typewriter('#typewriter', {
      strings: [t("contact_parlons"), t("contact_qui")],
      autoStart: true,
      loop: true,
      deleteSpeed: 10,
      delay: 100,
    });
  }, []);

  const handleArrowClick = () => {
    window.scrollTo({
      top: document.getElementById('map').offsetTop - 100,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Helmet>
        <title>{t("contact_title")}</title>
      </Helmet>
      <div className='wrapper font-mtserrat test h-[1200px] flex flex-col justify-center pb-20 lg:pb-0 lg:flex-row lg:items-center lg:min-h-screen lg:h-auto lg:gap-20'>
        <div className='flex flex-col gap-6 lg:w-1/2 items-center'>
          <h1 className='text-5xl font-bold text-center md:text-6xl w-full pt-64 lg:pt-0 h-96'><span id="typewriter"></span></h1>
          <p className='font-bold text-gray-400 text-center text-lg'>{t("contact_creons")}</p>
          <div className='mail-at-container flex p-4 items-center gap-5 rounded-xl z-2 bg-white w-full md:w-[350px] lg:w-[400px]'>
            <img src={Mallette}  alt='mallette' className='h-9'/>
            <div className='leading-4 '>
              <p className='text-sm font-semibold'> {t("contact_ecrire")}</p>
              <p className='text-sm font-bold text-primOrange'> {t("contact_mail")}</p>
            </div>
          </div>
          <div className="h-12 w-full flex items-center justify-center flex-col lg:justify-start mt-10">
            <p className='paragraphe mb-6'> {t("contact_carte")}</p>
            <div className="h-16 w-16 rounded-full animate-bounce cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={handleArrowClick}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
              </svg>
            </div>
          </div>
        </div>
        
        <div className='lg:w-1/2 lg:pb-[2vh]'>
          <Form />
        </div>
      </div>

      <div className='w-full p-3 md:p-4 flex gap-5 flex-col md:flex-row'  id="map">
        <div className='md:w-full lg:w-full xl:w-full' >
          <img src={Devanture} alt="79a rue de la Plaine des bouchers, 67100 Strasbourg" className='max-w-full' />
        </div>
        <div className='md:w-4/5 lg:w-4/5 xl:w-4/5'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17786.138804064456!2d7.737893273495516!3d48.56541953453263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796c997d96aaaab%3A0xfe88b3f41a38348e!2s79a%20Rue%20de%20la%20Plaine%20des%20Bouchers%2C%2067100%20Strasbourg!5e0!3m2!1sfr!2sfr!4v1687337153982!5m2!1sfr!2sfr" className="w-full" height="100%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>

      </div>
    </>
  )
}

