import { Fragment, useState } from "react";
import {Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import '../../../ressources/styles/index.css'
import Pins from '../../../ressources/images/pins.svg';
import Pen from '../../../ressources/images/pen.svg';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Example(props) {
  const [open, setOpen] = useState(false);
  const missions = [];
  const requis = [];
  const souhaits = [];
  const { t } = useTranslation();

  for (let i = 1; i <= 20 ; i++) {
    if (props[`mission${i}`]) {
      missions.push(props[`mission${i}`]);
    }
  
    if (props[`requis${i}`]) {
      requis.push(props[`requis${i}`]);
    }
  
    if (props[`souhait${i}`]) {
      souhaits.push(props[`souhait${i}`]);
    }
  }

  let style;
  if(props.type === "tech"){
    style = "px-4 text-center bg-[#CEE8FF] text-[#4252E5] rounded-sm uppercase";
  }
  else if(props.type === "business"){
    style = "px-4 text-center bg-[#FFE1E1] text-[#EF5B5B] rounded-sm uppercase";
  }

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
 
  return (
    <Fragment>
      <Accordion onClick={() => handleOpen(1)} open={open === 1} className="job-shadow rounded-lg p-6 cursor-pointer" data-aos="fade-up">
        <AccordionHeader className="font-bold text-gray-800 font-mtserrat text-xl sm:text-2xl md:text-3xl">
            {props.nomPoste}
        </AccordionHeader>
        <div onClick={() => handleOpen(1)} className="flex gap-7 font-roboto mt-3">
            <p className={style} >{props.type}</p>
            <div className="flex gap-2">
                <img src={Pen} alt="pins" className="w-3" />
                <p className="text-[#818181]">{props.contrat}</p>
            </div>
            <div className="flex gap-2">
                <img src={Pins} alt="pins" className="w-3" />
                <p className="text-[#818181]">{props.localisation}</p>
            </div>
        </div>
        <AccordionBody>
          {open === 1 && (
            <div className="font-roboto mt-8 mb-2">
              <div className="text-base">
                <p className="text-lg font-bold">{t("offredejob_mission")}</p>
                {missions.filter(mission => mission).map((mission, index) => (
                  <li key={index}>{mission}</li>
                ))}
              </div>
              <div className="text-base my-5"> 
                <p className="text-lg font-bold">{t("offredejob_profil")}</p>
                <p className="text-justify">
                  {props.profileRecherche}
                </p>
              </div>
              <div className="text-base 
              mb-5">
                <p className="text-lg font-bold">{t("offredejob_environnement")}</p>
                <p className="italic">{t("offredejob_requis")}</p>
                {requis.filter(requi => requi).map((requi, index) => (
                  <li key={index}>{requi}</li>
                ))}
                <p className="italic mt-6">{t("offredejob_souhaite")}</p>
                {souhaits.filter(souhait => souhait).map((souhait, index) => (
                  <li key={index}>{souhait}</li>
                ))}
              </div>
              <Link to="../contact" className="btn px-5">{t("offredejob_postuler")}</Link>
            </div>
          )}
        </AccordionBody>
      </Accordion>
    </Fragment>
  );
}