import React from 'react';
import { useTranslation } from 'react-i18next';

export default function MentionLegales() {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col items-center'>
      <h1 className="big-title mt-[150px]">{t("mentions_mentions")}</h1>
      <div className="mx-auto wrapper flex flex-col gap-7 my-10">
          <div className="text-justify">
              <h2 className="second-title">{t("mentions_editeurs")}</h2>
              <p className="text-base md:text-lg"></p>
              <p className="text-base md:text-lg">
              {t("mentions_site")}<br />
                <span>{t("mentions_ormaes")}</span> <br />
                {t("mentions_sas")}<br />
                {t("mentions_adresse1")}<br />
                {t("mentions_adresse2")}<br />
                {t("mentions_rcs")}<br />
                {t("mentions_tva")}  
              </p>
          </div>
          <div>
              <h2 className="text-gray-800 font-bold text-lg">{t("mentions_representants")} </h2>
              <p className="text-base md:text-lg">{t("mentions_laurenteric")}</p>
          </div>
          <div className="text-justify mt-5">
              <h2 className="second-title">{t("mentions_contact")}</h2>
              <p className="text-base md:text-lg">
              {t("mentions_siege")}<br />
              {t("mentions_adresse3")}<br />
              {t("mentions_telephone")}<br />
              {t("mentions_courriel")}<br />
              {t("mentions_dirpub")}<b>{t("mentions_ericlaurent")}</b> <br />
              </p>
          </div>
          <div className="text-justify mt-5"> 
          <h2 className="second-title">{t("mentions_cgu")}</h2>
              <p className="text-base md:text-lg">   
              {t("mentions_siteaccessible")} <br />
                <span> {t("mentions_ormaes2")} </span> <br />
                {t("mentions_cgufraccept")} <br />
              </p>
          </div>
          <div className="text-justify mt-5">
              <h2 className="second-title">{t("mentions_hebergeur")}</h2>
              <p className="text-base md:text-lg">
              {t("mentions_ovh")} <br />
              {t("mentions_ovhsiege")}  <br />
              {t("mentions_ovhadresse")}
              </p>
          </div>
          <div className="text-justify mt-5">
              <h2 className="second-title">{t("mentions_limiteresp")}</h2>
              <p className="text-base md:text-lg">
              {t("mentions_maj")} <br />
              {t("mentions_risques")} <br />
              {t("mentions_images")}<br />
              {t("mentions_liens")} 
              </p>
          </div>

          <div className="text-justify mt-5">
              <h2 className="second-title">{t("mentions_litiges")}</h2>
              <p className="text-base md:text-lg">
              {t("mentions_litiges_text")}
              </p>
          </div>

          <div className="text-justify mt-5">
              <h2 className="second-title">{t("mentions_pi")}</h2>
              <p className="text-base md:text-lg">
              {t("mentions_pi_donnees")}
              </p>
              <p className="text-base md:text-lg">
              {t("mentions_pi_fr")}<br />
              {t("mentions_pi_repro")}
              </p>
          </div>

          <div className="text-justify mt-5">
              <h2 className="second-title">{t("mentions_confid")}</h2>
              <p className="text-base md:text-lg">
              {t("mentions_confid_text")}
              </p>
          </div>

          <div className="text-justify mt-5">
              <h2 className="second-title">{t("mentions_pol_cookies")}</h2>
              <h3 className='text-gray-800 font-bold text-lg mb-1'>{t("mentions_cookie_def")}</h3>
              <p className="text-base md:text-lg">
              {t("mentions_cookie_def_text")}
              </p>
              <h3 className='text-gray-800 font-bold text-lg mt-5 mb-1'>{t("mentions_cookie_pourquoi")}</h3>
              <p className="text-base md:text-lg">
              {t("mentions_cookie_expl")}
              </p>
              <p className="text-base md:text-lg mt-3">
              {t("mentions_cookie_disable")}
              </p>
              <p className="text-base md:text-lg">
              {t("mentions_cookie_suppr")}
              </p>
              <p className="text-base md:text-lg">
              {t("mentions_cookie_politique")}
              </p>
          </div>
          <div className="text-justify mt-5">
              <h2 className="second-title">{t("mentions_remerciements")}</h2>
              <p className="text-base md:text-lg">
              {t("mentions_bonne_nav")}
              </p>
          </div>
      </div>
    </div>
  )
}
