import React from 'react';
import Button from "../../../components/Button";
import Card from '../../Carrieres/components/Card';
import ImageIntegration from '../../../ressources/images/vignette_integration.svg'
import ImageExpertise from '../../../ressources/images/vignette_expertise.svg'
import ImageDeveloppement from '../../../ressources/images/vignette_developpement.svg'
import i18n from "../../../I18next/i18n";
import { useTranslation } from 'react-i18next';

export default function Services() {
  const { t } = useTranslation();
  return (
    <div className='mt-40 wrapper xl:mt-80'>
        <h1 className="text-4xl font-bold text-center md:text-5xl md:mb-20" data-aos="zoom-in">
          {t("service_nos")}
            <span className="text-primOrange"> {t("service_services")}</span> 
        </h1>

        <div className='flex justify-center items-center mt-12 gap-12 flex-wrap'>
            <Card titre={t("service_expertise")} texte={t("service_expertise_text")} image={ImageExpertise}/>
            <Card titre={t("service_developpement")} texte={t("service_developpement_text")} image={ImageDeveloppement}/>
            <Card titre={t("service_integration")} texte={t("service_integration_text")} image={ImageIntegration}/>
        </div>

        <div className="flex justify-center mt-9 md:mt-16">
            <Button to="notre-offre" texte={t("service_decouvrir")}/>
        </div>
    </div>
  )
}
