import React from 'react'
import { Link } from "react-router-dom";
import logoOrmaes from "../ressources/images/logo-text-white.svg";
import logoLinkedin from "../ressources/images/linkedin.svg";
import { useTranslation } from 'react-i18next';

export default function Footer() {

  const { t } = useTranslation();

  return (
    <footer className="pt-12 border-t border-[#5D666E] bg-[#1b2631]">
      <div className="container">
        <div className="flex justify-center flex-col gap-5 items-center w-full">
          <Link to="/">
              <img
                src={logoOrmaes}
                alt="logo"
                className='h-7'
                data-aos='fade-up'
              />
          </Link>
          <a href="https://www.linkedin.com/company/ormaes/">
            <img 
              src={logoLinkedin} 
              alt="logo linkedin" 
              className='h-7 w-7'
              data-aos='fade-up'
            />
          </a>
        </div>

        <ul
          className="flex flex-col justify-center items-center text-white font-bold my-12 lg:flex-row  md:text-base xl:text-lg"
        >
          <li
            className="block py-3 px-6 transition hover:bg-[#22303E] rounded-[10px] hover:cursor-pointer text-center"
            data-aos="fade-up"
          >
            <Link to="/">{t("footer_accueil")}</Link>
          </li>
          <li
            className="block py-3 px-6 transition hover:bg-[#22303E] rounded-[10px] hover:cursor-pointer text-center" 
            data-aos="fade-up"
          >
            <Link to="qui-sommes-nous">{t("footer_qui")}</Link>
          </li>
          <li
            className="block py-3 px-6 transition hover:bg-[#22303E] rounded-[10px] hover:cursor-pointer text-center"
            data-aos="fade-up"
          >
            <Link to="notre-offre">{t("footer_offre")}</Link>
          </li>
          <li
            className="block py-3 px-6 transition hover:bg-[#22303E] rounded-[10px] hover:cursor-pointer text-center"
            data-aos="fade-up"
          >
            <Link to="carrieres">{t("footer_carrieres")}</Link>
          </li>
          <li
            className="block py-3 px-6 transition hover:bg-[#22303E] rounded-[10px] hover:cursor-pointer text-center"
            data-aos="fade-up"
          >
            <Link to="contact">{t("footer_contact")}</Link>
          </li>

          <li
            className="block py-3 px-6 transition hover:bg-[#22303E] rounded-[10px] hover:cursor-pointer text-center"
            data-aos="fade-up"
          >
            <Link to="mentions-legales">{t("footer_mentions")}</Link>
          </li>
        </ul>

        <div
          className="flex flex-col items-center justify-center text-sm  border-t-[1px] text-[#F7FAFC] py-12 md:flex-row"
        >
          <p>
            &copy; {t("footer_droits")}
          </p>
        </div>
      </div>
    </footer>
  )
}
