import {Routes, Route} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Accueil/Home';
import Contact from './pages/Contact/Contact';
import Error404 from './pages/Error404';
import AOS from 'aos'
import "aos/dist/aos.css";
import './ressources/styles/scrollbar.css';
import ScrollToTop from './components/ScrollToTop';
import QuiSommesNous from './pages/QuiSommesNous/QuiSommesNous';
import NotreOffre from './pages/NotreOffre/NotreOffre';
import Carrières from './pages/Carrieres/Carrieres';
import ValidationMessage from './pages/Contact/ValidationMessage';
import MentionsLegales from './pages/MentionsLegales/MentionsLegales';
import SpamMessage from './pages/Contact/Spam';
import LaVieChezOrmaes from './pages/LaVieChezOrmaes/LaVieChezOrmaes';
import Teapot from './pages/Teapot/Teapot';
import {SnackbarProvider} from "notistack";

export default function App() {
    AOS.init();
    return (
        <>
            <SnackbarProvider maxSnack={3} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
                <Header/>
                <main>
                    <ScrollToTop/>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="qui-sommes-nous" element={<QuiSommesNous/>}/>
                        <Route path="notre-offre" element={<NotreOffre/>}/>
                        <Route path="carrieres" element={<Carrières/>}/>
                        <Route path="contact" element={<Contact/>}/>
                        <Route path="mentions-legales" element={<MentionsLegales/>}/>
                        <Route path="envoie-reussi" element={<ValidationMessage/>}/>
                        <Route path="erreur-envoi" element={<SpamMessage/>}/>
                        <Route path="teapot" element={<Teapot/>}/>
                        <Route path="la-vie-chez-ormaes" element={<LaVieChezOrmaes/>}/>
                        <Route path='*' element={<Error404/>}/>
                    </Routes>
                </main>
                <Footer/>
            </SnackbarProvider>
        </>
    );
}

