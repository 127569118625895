// import React from 'react';
import { useEffect, useRef } from 'react';
import Typewriter from 'typewriter-effect/dist/core';
import '../../ressources/styles/expertises.css';
import Fleche from '../../ressources/images/fleche.svg';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import ControlledAccordions from '../../Accordion';
import { useTranslation } from 'react-i18next';

export default function NotreOffre() {
  const titleRef2 = useRef(null);
  const { t } = useTranslation();
    useEffect(() => {
    const title = titleRef2.current;
    const typewriter = new Typewriter(title, {
    loop: true
    });
    typewriter.typeString(t("offre_savoir"))
    .deleteAll()
    .pauseFor(1500)
    .deleteAll()
    .start(t);
  }, [t]); 

  return (
    <>
      <Helmet>
        <title>{t("offre_title")}</title>
      </Helmet>  
  
      <div className='flex flex-col justify-center overflow-hidden'>
        <div className='landing-img2 flex items-center justify-center pt-16 max-h-52'>
          <h1 className='text-white font-bold wrapper text-center text-4xl lg:text-6xl mt-8' ref={titleRef2}>{t("offre_savoir")}</h1>
        </div>       

        <div className='w-full mt-10 mb-10 text-left wrapper'>   
          <ControlledAccordions/>
        </div>

        <div className='wrapper flex flex-col lg:flex-row gap-[75px] w-full'>
        </div>

        <Link to='/contact' className='mb-24'  data-aos="fade-up">
          <div className='bg-image flex flex-col justify-center h-52'>
            <h2 className='text-3xl font-bold text-center mb-2 md:text-4xl xl:text-5xl wrapper text-white'>{t("offre_projet1")} <span className='text-primOrange'>{t("offre_projet2")}</span></h2>
            <img src={Fleche} alt="fleche" className='anim-fleche h-24' />
          </div>
        </Link>
      </div>
      </>
  )
}      


