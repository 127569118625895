import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Spam() {
  const { t } = useTranslation();

  return (
    <div className='h-screen flex flex-col items-center justify-center wrapper'>
      <h1 className='text-center text-3xl font-bold text-gray-700 md:text-5xl lg:text-6xl'>{t("spam_impossible")}</h1>
      <p className='text-xl text-center font-medium my-5 md:text-xl lg:text-2xl'>{t("spam_back")}</p>
      <Link to="../" className='btn'>{t("spam_Accueil")}</Link>
    </div>
  )
}
