import React from 'react';
import { useRef, useEffect } from 'react';
import Typewriter from 'typewriter-effect/dist/core';
import OffreDeJob from './components/OffreDeJob';
import Card from './components/Card';
import DevImage from '../../ressources/images/code.jfif'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function NousRejoindre() {
  const titleRef3 = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    const title = titleRef3.current;
    const typewriter = new Typewriter(title, {
    loop: true
    });
    typewriter.typeString(t("carrieres_rejoindre"))
    .deleteAll()
    .pauseFor(1500)
    .deleteAll()
    .start();
  }, [t]); 

  return (
    <>
      <Helmet>
        <title> {t("carrieres_title")}</title>
      </Helmet>
      <div className='flex flex-col justify-center overflox-x-hidden'>
        <div className='landing-img3 flex items-center justify-center pt-16 max-h-52'>
          <h1 className='text-white font-bold wrapper text-center text-4xl lg:text-6xl mt-8' ref={titleRef3}> {t("carrieres_rejoindre")}</h1>
        </div>
        <div className='wrapper mt-10'>
          <h1 className='big-title mb-2 lg:mb-5'> {t("carrieres_rejoindre")}</h1>
          <p className='paragraph text-center'>
          {t("carrieres_text1")}</p>
          <div className="flex flex-col xl:flex-row justify-between gap-8 mt-12" data-aos='fade-left'>

            <div className="w-full xl:w-8/12">
                <img className="w-full h-full rounded-xl hidden lg:block" src={DevImage} alt="" />
            </div>

            <div className="w-full xl:w-5/12 flex flex-col justify-center gap-7">
                <img className="w-full h-full rounded-xl block lg:hidden" src={DevImage} alt="" />
                <div>
                    <h3 className='value-subtitle'>{t("carrieres_efficaces")}</h3>
                    <p className="paragraph">
                    {t("carrieres_efficaces_text")}</p>
                </div>
                <div>
                    <h3 className='value-subtitle'>{t("carrieres_entreprenants")}</h3>
                    <p className="paragraph">
                    {t("carrieres_entreprenants_text")}
                    </p>
                </div>
                <div>
                    <h3 className='value-subtitle'>{t("carrieres_efficients")}</h3>
                    <p className="paragraph">
                    {t("carrieres_efficients_text")}</p>
                </div>
            </div>
          </div>

          <div className='mt-36'>
            <h2 className="big-title">{t("carrieres_profils")}</h2>
            <div className='flex flex-col flex-wrap items-center md:flex-row md:gap-10 lg:gap-0 lg:justify-between'>
              <Card titre={t("carrieres_passionne")} texte={t("carrieres_passionne_text")}/>
              <Card titre={t("carrieres_curieux")} texte={t("carrieres_curieux_text")}/>
              <Card titre={t("carrieres_talentueux")} texte={t("carrieres_talentueux_text")}/>
              <Card titre={t("carrieres_creatif")} texte={t("carrieres_creatif_text")}/>
            </div>
          </div>

          <div className='mb-24 mt-36'>
            <h1 className="big-title">{t("carrieres_offres")}</h1>
            <div className='flex gap-7 flex-col'>
                <OffreDeJob nomPoste={t("carrieres_offres_java")}  type={t("carrieres_offres_java_type")} contrat={t("carrieres_offres_java_contrat")} localisation={t("carrieres_offres_java_lieu")}
                mission1={t("carrieres_offres_java_mission1")}
                mission2={t("carrieres_offres_java_mission2")}
                mission3={t("carrieres_offres_java_mission3")}
                mission4={t("carrieres_offres_java_mission4")}
                mission5={t("carrieres_offres_java_mission5")}
                mission6={t("carrieres_offres_java_mission6")}
                mission7={t("carrieres_offres_java_mission7")}
                mission8={t("carrieres_offres_java_mission8")}

                profileRecherche={t("carrieres_offres_java_profil")}
                  
                requis1={t("carrieres_offres_java_requis1")}
                requis2={t("carrieres_offres_java_requis2")}
                requis3={t("carrieres_offres_java_requis3")}
                requis4={t("carrieres_offres_java_requis4")}
                requis5={t("carrieres_offres_java_requis5")}
                
                souhait1={t("carrieres_offres_java_souhait1")}
                souhait2={t("carrieres_offres_java_souhait2")}
                />
                
                <OffreDeJob nomPoste={t("carrieres_offres_mobile")} type={t("carrieres_offres_mobile_type")} contrat={t("carrieres_offres_mobile_contrat")} localisation={t("carrieres_offres_mobile_lieu")}
                mission1={t("carrieres_offres_mobile_mission1")}
                mission2={t("carrieres_offres_mobile_mission2")}
                mission3={t("carrieres_offres_mobile_mission3")}
                mission4={t("carrieres_offres_mobile_mission4")}
                mission5={t("carrieres_offres_mobile_mission5")}
                mission6={t("carrieres_offres_mobile_mission6")}
                mission7={t("carrieres_offres_mobile_mission7")}
                mission8={t("carrieres_offres_mobile_mission8")}
                mission9={t("carrieres_offres_mobile_mission9")}
                mission10={t("carrieres_offres_mobile_mission10")}
                mission11={t("carrieres_offres_mobile_mission11")}
                mission12={t("carrieres_offres_mobile_mission12")}
                mission13={t("carrieres_offres_mobile_mission13")}
                mission14={t("carrieres_offres_mobile_mission14")}

                profileRecherche={t("carrieres_offres_mobile_profil")}

                
                requis1={t("carrieres_offres_mobile_requis1")}
                requis2={t("carrieres_offres_mobile_requis2")}
                requis3={t("carrieres_offres_mobile_requis3")}

                souhait1={t("carrieres_offres_mobile_souhait1")}
                souhait2={t("carrieres_offres_mobile_souhait2")}
                souhait3={t("carrieres_offres_mobile_souhait3")}
                souhait4={t("carrieres_offres_mobile_souhait4")}
                souhait5={t("carrieres_offres_mobile_souhait5")}
                souhait6={t("carrieres_offres_mobile_souhait6")}
                />
                
                

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
