import React from 'react';
import Button from "../../../components/Button";
import { Link } from 'react-router-dom';
import i18n from "../../../I18next/i18n";
import { useTranslation } from 'react-i18next';

export default function Contact() {
  const { t } = useTranslation();
  return (
    <div className='mt-40 mb-10 xl:mt-64'>
      <div className="contact-img h-60 bg-no-repeat w-full bg-cover text-white flex justify-center flex-col md:h-72 xl:h-80"  data-aos="fade-up">
        <h1 className="text-3xl font-bold text-center mb-2 md:text-4xl xl:text-5xl" data-aos="fade-up">
        {t("contact_projet1")}
            <span className="text-primOrange"> {t("contact_projet2")} </span> <br /> {t("contact_collaborer1")}
            <span className="text-primOrange"> {t("contact_collaborer2")} </span>
        </h1>
        <p className='text-center font-semibold italic md:text-lg xl:text-xl'>
        {t("contact_voir1")}  
            <Link to={{ pathname: "/contact", hash: "#map" }}>
               <span className="text-primOrange jumping">&nbsp; {t("contact_voir2")} </span>
            </Link>
        </p>
        <div className="flex justify-center mt-7" data-aos="fade-up">
          <Button to="contact" texte={t("contact_contacter")}/>
        </div>
      </div>
    </div>
  )
}
