
import Landing from "./Content/Landing";
import About from "./Content/About";
import Services from "./Content/Services";
import Contact from "./Content/Contact";
import NousRejoindre from "./Content/NousRejoindre";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';


export default function Home() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("home_home")}</title>
      </Helmet>
      <Landing />
      <About />
      <Services />
      <Contact />
      <NousRejoindre />
    </>
  );
}