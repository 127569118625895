import React from 'react'
import Babyfoot from '../../ressources/images/Babyfoot.jpg';
import Button from '../../components/Button';

export default function LaVieChezOrmaes() {
  return (
    <div className='flex flex-col items-center'>
      <div className='landing-img flex items-center justify-center'>
          <h1 className='landing-title'>La vie dans notre equipe</h1>
      </div>

      <div className='wrapper mt-10'>
        <h1 className="big-title mb-12" data-aos="fade-right">Notre installation</h1>
        <div className='flex flex-col gap-16 items-center justify-center lg:flex-row mb-20'>
          <p className='paragraph lg:w-1/2'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit eius necessitatibus repellat laudantium minima commodi quo unde possimus placeat ab porro, recusandae numquam quos earum. Quas provident modi iure veritatis.
            Suscipit doloribus deleniti earum assumenda adipisci neque repudiandae minima iusto consequuntur perspiciatis dicta laborum eos consequatur, expedita consectetur facere ducimus minus nemo, sed non nam voluptate. Ipsa doloremque sunt modi.
            Vero aut earum quibusdam fuga. Quam totam fugiat voluptas reiciendis? Eius voluptates molestias soluta nobis non reprehenderit laborum omnis, beatae delectus sit! Odio delectus officia vero consequatur cum exercitationem
          </p>
          <p className='paragraph lg:w-1/2'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit eius necessitatibus repellat laudantium minima commodi quo unde possimus placeat ab porro, recusandae numquam quos earum. Quas provident modi iure veritatis.
            Suscipit doloribus deleniti earum assumenda adipisci neque repudiandae minima iusto consequuntur perspiciatis dicta laborum eos consequatur, expedita consectetur facere ducimus minus nemo, sed non nam voluptate. Ipsa doloremque sunt modi.
            Vero aut earum quibusdam fuga. Quam totam fugiat voluptas reiciendis? Eius voluptates molestias soluta nobis non reprehenderit laborum omnis, beatae delectus sit! Odio delectus officia vero consequatur cum exercitationem
          </p>
        </div>
        <div className='flex flex-col gap-5 justify-center sm:flex-row sm:flex-wrap'>
          <img src={Babyfoot} alt="" className='w-full max-w-[545px]'/>
          <img src={Babyfoot} alt="" className='w-full max-w-[545px]'/>
          <img src={Babyfoot} alt="" className='w-full max-w-[545px]'/>
        </div>

        <div className="flex flex-col lg:flex-row justify-between gap-8 mt-16 mb-11" data-aos='fade-left'>
          <div className="w-full lg:w-5/12 flex flex-col justify-center gap-7">
                <h2 className="value-bigtitle">Secteur (RH)</h2>
                <img className="w-full h-full rounded-xl block lg:hidden" src={Babyfoot} alt="tasse à café" />
                <div>
                    <h3 className='value-subtitle'>Elsass lorem ipsum</h3>
                    <p className="paragraph">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga eum autem a, architecto vel beatae voluptate cum ipsa. Veniam quo commodi enim incidunt ab aspernatur necessitatibus hic velit nesciunt facere.
                    </p>
                </div>
                <div>
                    <h3 className='value-subtitle'>Elsass lorem ipsum</h3>
                    <p className="paragraph">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore error sint ea aliquam vel? Mollitia aliquid iure optio recusandae pariatur culpa exercitationem, dignissimos similique aspernatur perspiciatis veniam suscipit impedit rerum!
                    </p>
                </div>
                <div>
                    <h3 className='value-subtitle'>Elsass lorem ipsum</h3>
                    <p className="paragraph">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem corrupti magnam sunt adipisci expedita vel nobis! Totam illo voluptatem, eum dignissimos deserunt veritatis non minus exercitationem, cum, atque deleniti? Hic?
                    </p>
                </div>
            </div>
            <div className="w-full lg:w-8/12">
                <img className="w-full h-full rounded-xl hidden lg:block" src={Babyfoot} alt="tasse à café" />
            </div>
        </div>
      </div>
      <div className='mt-200 mb-10 lg:mt-12 w-full'>
        <div className="decouvrir-img h-60 bg-no-repeat w-full bg-cover text-white flex justify-center flex-col md:h-72 xl:h-80">
            <h1 className="text-3xl font-bold text-center mb-2 md:text-4xl xl:text-5xl" data-aos="fade-left">
                Vous désirez découvrir notre
                <span className="text-primOrange"> offre ?</span> 
            </h1>
            <p className='text-center font-semibold italic md:text-lg xl:text-xl' data-aos="fade-right">
                Alors cliquez ci-dessous.
            </p>
            <div className="flex justify-center mt-7" data-aos="fade-left">
                <Button to="../notre-offre" texte="Notre offre"/>
            </div>
        </div>
      </div>
    </div>
  )
}
