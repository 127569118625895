import React from 'react';
import wave from "../../../ressources/images/wave.svg";
import waveXL from "../../../ressources/images/wavexl.svg";
import Button from "../../../components/Button";
import { Link } from 'react-router-dom';
import logo2 from "../../../ressources/images/logo-cts.png";
import logo3 from "../../../ressources/images/logo-electricite-strasbourg.png";
import logo4 from "../../../ressources/images/logo-omnicell.png";
import logo5 from "../../../ressources/images/logo-socomec.jpg";
import logo6 from "../../../ressources/images/logo-vetoquinol.png";
import logo7 from "../../../ressources/images/Logo_weewio.png";
import logo8 from "../../../ressources/images/logo_roiret_transport.png";
import { useTranslation } from 'react-i18next';
import '../../../ressources/styles/tailwind.css';

export default function Landing() {
    const { t } = useTranslation();

    return (
        <div className="font-mtserrat mt-32 w-full md:mt-44 landing-container">
            <div className='wrapper w-full flex xl:gap-4 xl:items-start' data-aos="fade-up">
                <div className="mb-16 xl:w-5/12 xl:flex xl:flex-col xl:content-center xl:mb-0">
                    <div dangerouslySetInnerHTML={{ __html: t('accueil_header') }} />
                    <p className="font-roboto font-bold leading-7 md:text-lg md:mt-6 xl:text-left xl:text-lg xl:leading-8 paragraph">
                        {t("landing_content")}
                    </p>
                    <div className="flex justify-center mt-7 xl:justify-start">
                        <Button to="contact" texte={t("accueil_parler_projet")} />
                    </div>
                    <p className="font-roboto font-bold leading-6 mt-20 md:text-base xl:text-left xl:text-sm xl:leading-6 paragraph">
                        {t("accueil_confiance")}
                    </p>
                    <div className="flex flex-wrap justify-start items-center gap-4 mt-5 min-h-[100px]">
                        <a href='https://www.cts-strasbourg.eu' target="_blank" rel="noreferrer"> 
                            <img src={logo2} alt="Compagnie des Transports Strasbourgeois" className="h-8 object-contain" /> 
                        </a>
                        <a href='https://www.es.fr' target="_blank" rel="noreferrer"> 
                        <img src={logo3} alt="Electricite de Strasbourg" className="h-8 object-contain" />
                        </a>
                        <a href='https://www.omnicell.com' target="_blank" rel="noreferrer"> 
                        <img src={logo4} alt="Omnicell" className="h-8 object-contain" />
                        </a>
                        <a href='https://www.socomec.fr/' target="_blank" rel="noreferrer"> 
                        <img src={logo5} alt="Socomec" className="h-8 object-contain" />
                        </a>
                        <a href='https://www.vetoquinol.com/' target="_blank" rel="noreferrer"> 
                        <img src={logo6} alt="Vetoquinol" className="h-8 object-contain" />
                        </a>
                        <a href='https://weewio.com/' target="_blank" rel="noreferrer"> 
                        <img src={logo7} alt="WEEWIO" className="h-8 object-contain" />
                        </a>
                        <a href='https://www.roiret-transport.fr/' target="_blank" rel="noreferrer"> 
                        <img src={logo8} alt="Roiret Transport" className="h-8 object-contain" />
                        </a>
                    </div>
                </div>
                <div className="img-background w-[300px] h-[250px] mx-auto rounded-[26px] relative md:w-[450px] md:h-[390px] xl:w-1/2 xl:h-[500px] mt-10 xl:mt-0">
                    <p className="developpement hidden md:inline">
                        <Link to={{ pathname: "/notre-offre", hash: "#dev" }}>
                            <span className="text-primOrange">&nbsp; {t("accueil_developpement")} </span>
                        </Link>
                    </p>
                    <p className="expertise hidden md:inline">
                        <Link to={{ pathname: "/notre-offre", hash: "#exp" }}>
                            <span className="text-primOrange">&nbsp; {t("accueil_expertise")} </span>
                        </Link>
                    </p>
                    <p className="integration hidden md:inline">
                        <Link to={{ pathname: "/notre-offre", hash: "#int" }}>
                            <span className="text-primOrange">&nbsp; {t("accueil_integration")} </span>
                        </Link>
                    </p>
                </div>
            </div>
            <img src={wave} className="w-[100vw] mt-4 md:hidden" alt='vague' />
            <img src={waveXL} className="hidden md:inline w-[100vw] md:mt-20" alt='vague' />
        </div>
    )
}
