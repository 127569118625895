import React from 'react'


export default function Cards(props) {
  return (
    <div className='w-full px-4 py-10 text-center font-roboto services-box flex flex-col gap-4 justify-center rounded-[10px] md:w-[45%] lg:w-[23%] h-80 md:h-96'
      data-aos='fade-up' data-aos-duration='1500'>
      <img src={props.image} className='mx-auto' alt="" />
      <h2 className='font-bold text-lg md:text-2xl text-gray-800 font-roboto align-first-line'>{props.titre}</h2>
      <div className='h-32 flex items-center'>
        <p className='paragraph text-center align-first-line'>{props.texte}</p>
      </div>
    </div>
  )
}
