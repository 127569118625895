import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

export default function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);
  const { t } = useTranslation();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    };
    
  return (
  <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon  sx={{ color: '#FF9703' }} />}
          aria-controls="panel1bh-content"
 //</Accordion>         expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
          id="panel1bh-header"
        >
          <Typography >
              <h1 className='big-title-accordion'>{t("notreoffre_01_titre")}</h1>
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          
          <div className='wrapper flex flex-col lg:flex-row gap-[75px] w-full'>
          <div className='flex flex-col w-full lg:w-1/2 paragraph'>
            <p>
            {t("notreoffre_01_paragraphe1")}  
            </p>  
            <p>
            {t("notreoffre_01_paragraphe2")}
            </p>
            <p>
            {t("notreoffre_01_paragraphe3")}
            </p>
            <p>
            <br></br>
            {t("notreoffre_01_paragraphe4")}
            </p>
            <br></br>
            <p>{t("notreoffre_01_paragraphe5")}</p>
            
            
          </div>
          <div className='flex flex-col w-full lg:w-1/2  paragraph'>
          <p>{t("notreoffre_01_paragraphe6")}</p>
            
            <li>{t("notreoffre_01_li1")}</li>
            <li>{t("notreoffre_01_li2")}</li>
            <li>{t("notreoffre_01_li3")}</li>
            <li>{t("notreoffre_01_li4")}</li>
            <li>{t("notreoffre_01_li5")}</li>
            <li>{t("notreoffre_01_li6")}</li>
            <li>{t("notreoffre_01_li7")}</li>
            <li>{t("notreoffre_01_li8")}</li>
            <li>{t("notreoffre_01_li9")}</li>
            <li>{t("notreoffre_01_li10")}</li>
            <li>{t("notreoffre_01_li11")}</li>     
            <br></br>                   
            <p>{t("notreoffre_01_p1")}</p>
            <li>{t("notreoffre_01_li12")}</li>
            <li>{t("notreoffre_01_li13")}</li>
            <li>{t("notreoffre_01_li14")}</li>
            <li>{t("notreoffre_01_li15")}</li>
            <li>{t("notreoffre_01_li16")}</li>
            <p>
            <br></br>
            {t("notreoffre_01_paragraphe7")}
            </p>
          </div>
        </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#FF9703' }} />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography>
            <h1 className='big-title-accordion'>{t("notreoffre_02_titre")}</h1>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className='wrapper flex flex-col lg:flex-row gap-[75px] w-full'>
            <div className='flex flex-col w-full lg:w-2/2  paragraph'>
              <p>
              {t("notreoffre_02_paragraphe1")} 
              </p>  
              <p>
                <br></br>
                {t("notreoffre_02_paragraphe2")}
                  <li>{t("notreoffre_02_li1")}</li>
                  <li>{t("notreoffre_02_li2")}</li>
                  <li>{t("notreoffre_02_li3")}</li>
              </p>
              <br></br>
              <p>
              {t("notreoffre_02_paragraphe3")}
                  <li>{t("notreoffre_02_li4")}</li>
                  <li>{t("notreoffre_02_li5")}</li>
                  <li>{t("notreoffre_02_li6")}</li>
                  <li>{t("notreoffre_02_li7")}</li>
              </p>
            </div>
            
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#FF9703' }} />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography>
            <h1 className='big-title-accordion'>{t("notreoffre_03_titre")}</h1>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className='wrapper flex flex-col lg:flex-row gap-[75px] w-full'>
            <div className='flex flex-col w-full lg:w-2/2  paragraph'>
            <p>
            {t("notreoffre_03_paragraphe1")}
            </p>  
              <li>{t("notreoffre_03_li1")}</li>
              <li>{t("notreoffre_03_li2")}</li>
              <li>{t("notreoffre_03_li3")}</li>
              <li>{t("notreoffre_03_li4")}</li>
              <li>{t("notreoffre_03_li5")}</li>
              <li>{t("notreoffre_03_li6")}</li>
              <li>{t("notreoffre_03_li7")}</li>
              <li>{t("notreoffre_03_li8")}</li>
              <li>{t("notreoffre_03_li9")}</li>
              <li>{t("notreoffre_03_li10")}</li>
              <li>{t("notreoffre_03_li11")}</li>
              <li>{t("notreoffre_03_li12")}</li>
              <li>{t("notreoffre_03_li13")}</li>
              <li>{t("notreoffre_03_li14")}</li>
              <li>{t("notreoffre_03_li15")}</li>
              <li>{t("notreoffre_03_li16")}</li>
              <li>{t("notreoffre_03_li17")}</li>
              <li>{t("notreoffre_03_li18")}</li>
              <li>{t("notreoffre_03_li19")}</li>
            <br></br>
            <p>
            <b> {t("notreoffre_03_paragraphe2")}</b>
            </p>  
           </div>    
          </div>
{/*
          <div className='flex flex-col w-full lg:w-1/2  paragraph invisible'>
           <p>
              Le développement logiciel se complexifiant en permanence, nous disposons d’un important réseau de partenaires permettant 
              d’adjoindre aux projets que nous menons, des expertises pointues dans différents domaines. 
            </p>
            <p>
              Nous sommes en capacité de vous accompagner sur tout le cycle de vie de votre projet.  
            </p>
            <li>Accompagnement amont permettant la formalisation de l’expression de besoin et des exigences</li>
            <li>Accompagnement au choix : make or buy</li>
            <li>Accompagnement à l’architecture et aux choix technologiques tout en prenant en considération l’urbanisation de votre système d’information</li>
            <li>Intégration de la solution au système existant et aux applications amont et aval.</li>
            <li>Accompagnement à la recette (UAT)</li>
            <li>Accompagnement au déploiement</li>
            <li>MCO, support et maintenance de tout type en exploitation</li>
            <p>Les types d’applications que nous concevons : </p>
            <li>Applications Web qui sont « responsives » et « adaptatives »</li>
            <li>Applications client lourd (desktop)</li>
            <li>Applications de type serveur</li>
            <li>Portails en utilisation de CMS</li>
            <li>Applications mobiles IOS et Android</li>
            </div>
  */}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div> 
  );
} 

