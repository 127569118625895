import {useState} from "react";
import langue_francaise from "../../ressources/images/French_language.svg";
import language_english from "../../ressources/images/English_language.svg";
import Select from "react-select";
import i18n from "../../I18next/i18n";

const options = [
    {value: 'fr', label: 'fr', image: langue_francaise},
    {value: 'en', label: 'en', image: language_english},
];

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor:  'orange',
        boxShadow: state.isFocused ? '0 0 0 1px orange' : null,
        '&:hover': {
            borderColor: 'orange',
        },
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: 'orange', // Change the color of the dropdown indicator
        '&:hover': {
            color: 'orange',
        },
    }),
};

export default function LanguageSelector() {

    const [selectedOption, setSelectedOption] = useState(options[0]);

    const handleChange = (option) => {
        setSelectedOption(option);
        i18n.changeLanguage(option.value);
    };

    return (
        <Select
            value={selectedOption}
            options={options}
            styles={customStyles}
            formatOptionLabel={country => (
                <div className="country-option">
                    <img src={country.image} alt="country-language" width="30" height="30"/>
                </div>
            )}
            onChange={handleChange}
        />
    );
}