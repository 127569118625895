import React from 'react';
import Button from '../components/Button';
import { useTranslation } from 'react-i18next';

export default function Error404() {
  const { t } = useTranslation();
  return (
    <div className='h-screen wrapper flex justify-center items-center bg-w'>
        <div className='flex flex-col'>
            <p className='text-[7vw] lg:text-[70px] font-roboto text-center'>{t("error_oups")}</p>
            <p className='text-[5vw] lg:text-[45px] font-roboto text-center'>{t("error_encours_dev")}</p>
            <p className='text-[5vw] lg:text-[45px] font-roboto text-center italic mt-4'>{t("error_code_erreur")}</p>
            <div className='mx-auto mt-6'>
                <Button to='/' texte={t("error_accueil")} className='mx-auto'/>
            </div>
        </div>
    </div>
  )
}
