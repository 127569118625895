import React from 'react';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from "notistack";

export default function Form() {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {

        const liste = document.getElementById('liste');
        let p;

        liste.addEventListener('change', () => {
            if (liste.value === 'Recrutement') {
                p = document.createElement('p');
                p.innerText = t("form_cv");
                p.className = 'px-4 italic';
                liste.parentNode.appendChild(p);
            } else if (p && p.parentNode === liste.parentNode) {
                liste.parentNode.removeChild(p);
            }
        });
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);

        try {
            const response = await fetch('/api/send_mail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    subject: formData.get('liste'),
                    message: formData.get('message'),
                    mail: formData.get('mail'),
                    nomPrenom: formData.get('nomPrenom'),
                    tel: formData.get('tel'),
                })
            });

            const result = await response.json();
            if (result.success) {
                enqueueSnackbar(t("form_snackbar_success"), { variant: 'success' });
                event.target.reset();
            } else {
                enqueueSnackbar(t("form_snackbar_success"), { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar('Error: ' + error.message, { variant: 'error' });
            console.error('Unexpected error: ', error);
        }
    };

    return (
        <form className='p-3 rounded-xl mt-28 bg-white' onSubmit={handleSubmit} encType="multipart/form-data">
            <fieldset className='flex flex-col gap-4 lg:gap-7'>
                <h1 className='text-[4.75vw] text-center font-semibold text-[#444B5F] mb-3 sm:text-3xl'>{t("form_message")}</h1>
                <div>
                    <input
                        type="text"
                        name="nomPrenom"
                        id="nomPrenom"
                        placeholder={t("form_nom")}
                        className='outline-[#F5B100] bg-[#fff] border border-[#DCDCDC] px-3 py-2 rounded-md w-full lg:h-12 lg:text-lg'
                        required/>
                </div>
                <div>
                    <input
                        type="email"
                        name="mail"
                        id="mail"
                        placeholder={t("form_email")}
                        className='outline-[#F5B100] bg-[#fff] border border-[#DCDCDC] px-3 py-2 rounded-md w-full lg:h-12 lg:text-lg'
                        required/>
                </div>
                <div>
                    <input
                        type="tel"
                        name="tel"
                        id="tel"
                        placeholder={t("form_phone")}
                        className='outline-[#F5B100] bg-[#fff] border border-[#DCDCDC] px-3 py-2 rounded-md w-full lg:h-12 lg:text-lg'/>
                </div>
                <div>
                    <select name="liste" id="liste"
                            className='outline-[#F5B100] bg-[#fff] border border-[#DCDCDC] px-3 py-2 rounded-md w-full lg:h-12 lg:text-lg'
                            required>
                        <option value="Prestation/services">{t("form_value1")}</option>
                        <option value="Recrutement">{t("form_value2")}</option>
                        <option value="Autre">{t("form_value3")}</option>
                    </select>
                </div>
                <div>
                    <label className='font-bold'>{t("form_parle")}</label>
                    <textarea type="text" name="message" id="message" placeholder=''
                              className='outline-[#F5B100] bg-[#fff] border border-[#DCDCDC] px-3 py-2 rounded-md w-full h-32 mt-2  lg:text-lg'
                              required/>
                </div>

                <div className="flex items-center justify-center w-full hidden">
                    <label htmlFor="dropzone-file"
                           className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none"
                                 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                                className="font-semibold">{t("form_upload")}</span>{t("form_drag")}</p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">{t("form_pdf")}</p>
                        </div>
                        <input id="dropzone-file" type="file" name="userImport" className="hidden"/>
                    </label>
                </div>
            </fieldset>

            <button type="submit" value="Send"
                    className='bg-primOrange text-white py-3 px-5 rounded-lg font-bold w-full mt-5'>{t("form_envoyer")}</button>
        </form>
    )
}
