import React, { useEffect } from 'react';
import Button from '../../components/Button';
import TeapotImg from '../../ressources/images/teapot.png'
import '../../ressources/styles/teapot.css'
import { useTranslation } from 'react-i18next';

export default function Teapot() {
  const { t } = useTranslation();

  return (
    <div className='wrapper flex justify-center items-center min-h-screen'>
      <div className='flex flex-col items-center'>
        <div className='flex flex-col items-center'>
          <div>
            <p className='text-[7vw] lg:text-[70px] font-roboto text-center teapotText'>{t("teapot_iam")}</p>
            <p className='text-[5vw] lg:text-[45px] font-roboto text-center italic mt-4 teapotError'>{t("teapot_418")}</p>
          </div>
          <img src={TeapotImg} alt={t("teapot_alt")} className='teapot w-[200px] h-[200px] md:w-[300px] md:h-[300px] lg:w-[400px] lg:h-[400px]'/>
          <p className='paragraph text-center'>{t("teapot_attention")}</p>
        </div>
        <div className='mx-auto mt-6 teapotButton'>
          <Button to='/' texte={t("teapot_accueil")} className='mx-auto'/>
        </div>
      </div>
    </div>
  )
}
